import React from "react"

function CheckIcon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5303 5.96967C20.8232 6.26256 20.8232 6.73744 20.5303 7.03033L9.53033 18.0303C9.23744 18.3232 8.76256 18.3232 8.46967 18.0303L3.46967 13.0303C3.17678 12.7374 3.17678 12.2626 3.46967 11.9697C3.76256 11.6768 4.23744 11.6768 4.53033 11.9697L9 16.4393L19.4697 5.96967C19.7626 5.67678 20.2374 5.67678 20.5303 5.96967Z"
        fill="#373F45"
      />
    </svg>
  )
}

function CloseIcon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.53033 6.46967C7.23744 6.17678 6.76256 6.17678 6.46967 6.46967C6.17678 6.76256 6.17678 7.23744 6.46967 7.53033L10.9393 12L6.46967 16.4697C6.17678 16.7626 6.17678 17.2374 6.46967 17.5303C6.76256 17.8232 7.23744 17.8232 7.53033 17.5303L12 13.0607L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L13.0607 12L17.5303 7.53033C17.8232 7.23744 17.8232 6.76256 17.5303 6.46967C17.2374 6.17678 16.7626 6.17678 16.4697 6.46967L12 10.9393L7.53033 6.46967Z"
        fill="#373F45"
      />
    </svg>
  )
}

export default function EmailValidation({
  emailInvalid,
  emailSuggestion,
  onSuggestionAccepted,
  onSuggestionIgnored
}) {
  return (
    <div>
      {emailInvalid && emailSuggestion && (
        <div
          style={{
            display: "flex",
            borderRadius: "4px",
            padding: "8px",
            marginTop: "6px",
            color: "#373f45",
            backgroundColor: "#FFEFF0",
            fontSize: "14px"
          }}
        >
          <div
            style={{
              paddingTop: "2px",
              paddingLeft: "9px"
            }}
          >
            Did you mean{" "}
            <span
              style={{
                fontWeight: "600",
                color: "#373f45"
              }}
            >
              {emailSuggestion}
            </span>
            ?
          </div>

          <div
            style={{
              height: "27px",
              paddingTop: "2px",
              marginLeft: "auto",
              whiteSpace: "nowrap"
            }}
          >
            <button
              label="Add suggested email to input field"
              onClick={onSuggestionAccepted}
              style={{
                width: "24px",
                height: "24px",
                padding: "2px",
                marginRight: "8px",
                cursor: "pointer",
                borderRadius: "4px",
                backgroundColor: "#FEDFE1"
              }}
            >
              <CheckIcon />
            </button>

            <button
              label="Decline email suggestion"
              onClick={onSuggestionIgnored}
              style={{
                width: "24px",
                height: "24px",
                padding: "2px",
                cursor: "pointer",
                borderRadius: "4px",
                backgroundColor: "#FEDFE1"
              }}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      )}

      {emailInvalid && !emailSuggestion && (
        <div
          style={{
            display: "flex",
            borderRadius: "4px",
            padding: "8px",
            marginTop: "6px",
            color: "#373f45",
            backgroundColor: "#FFEFF0",
            fontSize: "14px"
          }}
        >
          <div
            style={{
              paddingTop: "2px",
              paddingLeft: "9px"
            }}
          >
            Invalid email. Use anyway?
          </div>
          <div
            style={{
              height: "27px",
              paddingTop: "2px",
              marginLeft: "auto"
            }}
          >
            <button
              label="Ignore email warning"
              onClick={onSuggestionIgnored}
              style={{
                width: "24px",
                height: "24px",
                padding: "2px",
                cursor: "pointer",
                borderRadius: "4px",
                backgroundColor: "#FEDFE1"
              }}
            >
              <CheckIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
