import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useComponents } from "../../components"
import { exitAnimation } from "./animation"

function CantBuyPage({ isVisible, onAnimationDone }) {
  const Elements = useComponents()

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <motion.div
          className="formkit-card-inner--fulfillment"
          key="error-card-inner"
          exit={exitAnimation}
        >
          <motion.div
            key="main-content-cantbuy"
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
          >
            <header className="formkit-confirmation-header">
              <Elements.Heading
                className="formkit-heading-primary font-display"
                name="confirmation_title"
                defaults={{
                  content: "Oops!"
                }}
              />
            </header>
            <div className="formkit-main" role="main">
              <p>This account is not currently accepting payments.</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CantBuyPage
