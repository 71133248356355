import { useState, useEffect } from "react"
import useFetch from "../../utils/use-fetch"

function useCoupon(context, code) {
  const [coupon, setCoupon] = useState(null)
  const [errors, setErrors] = useState(null)
  const [request] = useFetch(`https://${context.domain}/commerce/products/${context.product_id}/coupon`)

  useEffect(() => {
    if (!code) {
      return
    }
    const fn = async () => {
      try {
        const response = await request.put({
          data: {
            name: code
          }
        })

        if (response.coupon) {
          setCoupon(await response.coupon)
        } else {
          setErrors("The coupon is not valid")
        }
      } catch {
        setErrors("The coupon is not valid.")
      }
    }
    fn()
  }, [code])

  return [coupon, errors, () => {
    setCoupon(null)
  }]
}

export default useCoupon
