import user from "./user"
/**
 * Reporting Events are events that are meant to be viewed by the user via reporting UI.
 *
 * Viewing the "pay" page of the commerce template:
 *
 *  reportingEvent("view", {
 *     source_name: "pay",
 *     target_type: "Commerce::Product",
 *     target_name: "My Product",
 *     target_id: 1234
 *   })
 *
 * @param {string} name - the name of the event
 * @param {object} properties - the properties of the event
 * @return {Promise} - a promise that resolves when the event is sent
 **/
async function reportingEvent(name, properties = {}) {
  const referrer =
    document.referrer == "" ? window.location.href : document.referrer

  const data = {
    name: name,
    properties: {
      referrer: referrer,
      source_path: window.location.pathname,
      user_agent: navigator.userAgent,
      trace_id: user.id(),
      ...properties
    }
  }

  try {
    await fetch("/ui/reporting_event", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
  } catch (e) {
    // Ignore
  }
}

export default reportingEvent
