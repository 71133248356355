export const enterAnimation = {
  opacity: 1,
  transition: {
    type: "spring",
    stiffness: 200,
    damping: 30,
    opacity: { delay: 0.1 }
  }
}

export const enterDelayedAnimation = {
  opacity: 1,
  transition: {
    type: "spring",
    stiffness: 200,
    damping: 30,
    opacity: { delay: 0.3 }
  },
}

export const exitAnimation = {
  opacity: 0,
  transition: {
    type: "spring",
    stiffness: 300,
    damping: 35
  },
  transitionEnd: {
    opacity: 0
  }
}
