import "regenerator-runtime/runtime"
import React, { useState, useEffect } from "react"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import { motion } from "framer-motion"
import createTemplate from "../../create-template"
import { useComponents } from "../../components"
import SalePage from "./sale-page"
import Checkout from "./checkout"
import CheckoutOrders from "./checkout-orders"
import Fulfillment from "./fulfillment"
import FulfillmentOrders from "./fulfillment-orders"
import ErrorBoundary from "./error-boundary"
import useCoupon from "./use-coupon"
import CantBuyPage from "./cantbuy-page"
import reportingEvent from "../../utils/reporting-event"

import "@reach/dialog/styles.css"

const postMessage = (message) => {
  window.parent.postMessage(JSON.stringify(message), "*")
}

function Dialog({ children }) {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const message = JSON.parse(event.data)

        switch (message.type) {
          case "show-modal":
            setActive(true)
            return true
        }
      } catch (e) {
        // The message wasn't JSON, so we don't handle it.
      }
    }

    window.addEventListener("message", handleMessage)
    return () => window.removeEventListener("message", handleMessage)
  }, [])

  return (
    <DialogOverlay
      className="commerce-overlay"
      isOpen={active}
      onDismiss={() => {
        setActive(false)
        postMessage({ type: "hide-modal" })
      }}
    >
      <DialogContent
        className="commerce-dialog"
        aria-label="Purchase"
        style={{ overflow: "hidden", position: "relative" }}
        key="dialog"
        initial={{ opacity: 0, y: -15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

function Page({ children }) {
  return <div className="commerce-page">{children}</div>
}

function useSearchParams() {
  const [params] = useState(() => {
    try {
      return Object.fromEntries(new URL(document.location).searchParams)
    } catch (e) {
      return {}
    }
  })
  return params
}

function isComponentVisible(name, page, transition) {
  return name == page && name == transition
}

function defaultPrice(prices) {
  return prices[0]
}

function Commerce({
  styles,
  editing,
  currentView = "buy",
  context = {},
  ...props
}) {
  const params = useSearchParams()

  const [currentPage, setCurrentPage] = useState(currentView || "buy")
  const [currentTransition, setTransition] = useState(currentView || "buy")
  const [purchase, setPurchase] = useState(null)
  /* eslint-disable-next-line */
  const [coupon, couponErrors, clearCoupon] = useCoupon(context, params.promo)

  const prices = context.prices.plans
  const priceType = context.prices.type
  const maxQuantity = context.max_quantity
  const [selectedPrice, setSelectedPrice] = useState(defaultPrice(prices))
  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const Elements = useComponents()

  // Changing tab in the editor
  useEffect(() => {
    if (!editing) {
      return
    }

    currentView = currentView || "buy"
    if (currentPage !== currentView) {
      transition(currentView)
    }
  }, [currentView])

  useEffect(() => {
    if (currentPage == "throwaway" || editing) {
      return
    }

    reportingEvent("view", {
      source_name: currentPage,
      source_type: "landing_page",
      target_type: "commerce_product",
      target_name: context.name,
      target_id: context.product_id,
      tags: ["template-commerce"]
    })
  }, [currentPage, context, editing])

  // Load initial page e.g. `?step=checkout`
  useEffect(() => {
    if (editing) {
      return
    }

    if (params.step == "checkout" && !props.ignoreSearchParams) {
      setCurrentPage("checkout")
      setTransition("checkout")
    }
  }, [])

  if (editing && !purchase) {
    const samplePurchase = {
      created_at: new Date().toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric"
      }),
      price: selectedPrice,
      quantity: selectedQuantity,
      cardBrand: "visa",
      last4: 4242
    }
    if (context.orders) {
      samplePurchase.fulfillment_details = []
      samplePurchase.total = selectedPrice.formatted
    }

    setPurchase(samplePurchase)
  }

  const setStepParam = (step) => {
    if (editing) {
      return
    }
    try {
      const url = new URL(document.location)
      if (step == "checkout") {
        url.searchParams.set("step", step)
        window.history.replaceState({}, "", url)
      } else {
        url.searchParams.delete("step")
        window.history.replaceState({}, "", url)
      }
    } catch (e) {
      return
    }
  }

  const Wrapper = params.embed ? Dialog : Page

  const variants = {
    buy: { maxWidth: 960, width: "100%", height: "auto" },
    transition: { maxWidth: 500, width: "100%", height: 400 },
    checkout: { maxWidth: 500, width: "100%", height: "auto" },
    cantbuy: { maxWidth: 960, width: "100%", height: "auto" },
    fulfillment: { maxWidth: 500, width: "100%", height: "auto" },
    error: { maxWidth: 960, width: "100%", height: "auto" }
  }
  const current = variants[currentTransition]

  const toCheckout = () => {
    if (editing) {
      return
    }
    transition("checkout")
  }

  const toBuy = () => {
    if (editing) {
      return
    }
    transition("buy")
  }

  const toCantBuy = () => {
    transition("cantbuy")
  }

  const transition = (newPage) => {
    setCurrentPage("throwaway")

    setTimeout(() => {
      setTransition("transition")
      setCurrentPage(newPage)
      setTimeout(() => {
        setTransition(newPage)
        setStepParam(newPage)
      }, 275)
    }, 300)
  }

  const fulfill = (purchase) => {
    setPurchase({
      created_at: new Date().toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric"
      }),
      price: selectedPrice,
      quantity: selectedQuantity,
      coupon: coupon,
      ...purchase
    })

    transition("fulfillment")
  }

  const checkoutComponent = context.orders ? (
    <CheckoutOrders
      styles={styles}
      price={selectedPrice}
      quantity={selectedQuantity}
      coupon={coupon}
      context={context}
      onPurchase={(purchase) => fulfill(purchase)}
      onBack={toBuy}
      onCouponFailure={clearCoupon}
      isVisible={isComponentVisible("checkout", currentPage, currentTransition)}
      editing={editing}
    />
  ) : (
    <Checkout
      styles={styles}
      price={selectedPrice}
      quantity={selectedQuantity}
      coupon={coupon}
      context={context}
      onPurchase={(purchase) => fulfill(purchase)}
      onBack={toBuy}
      onCouponFailure={clearCoupon}
      isVisible={isComponentVisible("checkout", currentPage, currentTransition)}
    />
  )

  const fulfillmentComponent = context.orders ? (
    <FulfillmentOrders
      styles={styles}
      order={purchase}
      isVisible={isComponentVisible(
        "fulfillment",
        currentPage,
        currentTransition
      )}
    />
  ) : (
    <Fulfillment
      styles={styles}
      purchase={purchase}
      isVisible={isComponentVisible(
        "fulfillment",
        currentPage,
        currentTransition
      )}
    />
  )

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
      context={context}
    >
      <Wrapper>
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("background")}
        />

        <motion.div
          className={`formkit-card ${
            currentPage ? `formkit-card--${currentPage}` : ""
          }`}
          style={styles.forElement("card_background")}
          initial={{ maxWidth: 960, width: "100%", height: 100 }}
          animate={current}
          transition={{
            type: "tween",
            ease: "anticipate",
            duration: 0.3
          }}
          key="main-card"
        >
          <ErrorBoundary>
            <SalePage
              styles={styles}
              prices={prices}
              priceType={priceType}
              coupon={coupon}
              couponErrors={couponErrors}
              maxQuantity={maxQuantity}
              onClick={context.published ? toCheckout : toCantBuy}
              isVisible={isComponentVisible(
                "buy",
                currentPage,
                currentTransition
              )}
              selectPrice={setSelectedPrice}
              selectQuantity={setSelectedQuantity}
              activePrice={selectedPrice}
            />
            {checkoutComponent}
            {fulfillmentComponent}
            <CantBuyPage
              styles={styles}
              isVisible={isComponentVisible(
                "cantbuy",
                currentPage,
                currentTransition
              )}
            />
          </ErrorBoundary>
        </motion.div>

        <footer>
          <Elements.PoweredByConvertKit variant="block" />
        </footer>
      </Wrapper>
    </Elements.LandingPage>
  )
}

export default createTemplate(Commerce, { name: "Commerce" })
