import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useComponents } from "../../components"
import {
  enterAnimation,
  enterDelayedAnimation,
  exitAnimation
} from "./animation"
import Cards from "./cards"
import { formatCheckout } from "../../utils/price-functions"

function Fulfillment({ purchase, isVisible, onAnimationDone }) {
  const Elements = useComponents()

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <motion.div
          className="formkit-card-inner--fulfillment"
          key="fulfillment-card-inner"
          animate={enterAnimation}
          exit={exitAnimation}
        >
          <Cards />
          <motion.div
            key="main-content-fulfillment"
            initial={{ opacity: 0 }}
            animate={enterDelayedAnimation}
            exit={exitAnimation}
          >
            <header className="formkit-confirmation-header">
              <Elements.Heading
                className="formkit-heading-primary font-display"
                name="confirmation_title"
                defaults={{
                  content: "Thank You!"
                }}
              />
            </header>
            <div className="formkit-main" role="main">
              <dl className="formkit-purchase-confirmation-details">
                <div>
                  <dt>Amount Paid</dt>
                  <dd>
                    {formatCheckout(
                      purchase.price,
                      purchase.coupon,
                      purchase.quantity
                    )}
                  </dd>
                </div>
                <div>
                  <dt>Order date</dt>
                  <dd>{purchase.created_at}</dd>
                </div>
                <div>
                  <dt>Payment method</dt>
                  <dd className="formkit-purchase-confirmation-card">
                    <svg className={`svg-card-${purchase.cardBrand}`}>
                      <use xlinkHref={`#${purchase.cardBrand}`} />
                    </svg>
                    {purchase.last4}
                  </dd>
                </div>
                {purchase.price.installments && (
                  <div>
                    <dt>Payment</dt>
                    <dd>1 of {purchase.price.installments}</dd>
                  </div>
                )}
                {nextInstallmentDateFormatted(purchase) && (
                  <div>
                    <dt>Next Payment</dt>
                    <dd>{nextInstallmentDateFormatted(purchase)}</dd>
                  </div>
                )}
              </dl>
              <Elements.Content
                className="formkit-content"
                group="content"
                name="content_fulfillment"
                defaults={{
                  content:
                    "<p>Your file has been emailed to you along with a receipt for your transaction. Click the link below to download it now.</p>"
                }}
              />
              {purchase.paidPostsUrl && (
                <div>
                  <a href={purchase.paidPostsUrl}>
                    Click here to view the newsletter
                  </a>
                </div>
              )}
            </div>
            {purchase.fulfillmentUrl && (
              <div className="formkit-purchase-confirmation-ticket-stub">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 492 24"
                >
                  <path
                    fill="currentColor"
                    stroke="currentColor"
                    d="M11.5 12c0 6.1837-4.88069 11.2275-11 11.4893V.510674C6.61931.772452 11.5 5.81625 11.5 12zM491.5 23.4893c-6.119-.2618-11-5.3056-11-11.4893 0-6.18375 4.881-11.227548 11-11.489326V23.4893z"
                  />
                  <path
                    stroke="currentColor"
                    strokeDasharray="8 8"
                    strokeMiterlimit="14.3356"
                    strokeWidth="2"
                    d="M12 12h468"
                  />
                </svg>
                <div className="formkit-button-container">
                  <motion.div
                    whileHover={{ scale: 1.025 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Elements.Button
                      as="a"
                      className="formkit-purchase-button"
                      group="purchase_button"
                      name="purchase-download-button"
                      defaults={{
                        content: "Download Now"
                      }}
                      href={purchase.fulfillmentUrl}
                    />
                  </motion.div>
                </div>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const nextInstallmentDateFormatted = (purchase) => {
  // Depending on if we're previewing a price or have a successful purchase, the
  // next_installment_date_formatted will be available on different objects. This
  // function will return either, if available.
  if (
    purchase.next_installment_date_formatted ||
    purchase.price.next_installment_date_formatted
  ) {
    return (
      purchase.next_installment_date_formatted ||
      purchase.price.next_installment_date_formatted
    )
  } else {
    return false
  }
}

export default Fulfillment
