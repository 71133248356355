import React from "react"

export default function SelectInput({
  label,
  placeholder,
  labelStyles,
  name,
  value,
  onChange,
  options,
  ...rest
}) {
  return (
    <div className="formkit-field">
      <label htmlFor={name} style={labelStyles}>
        {label}
      </label>

      <select
        id={name}
        className="formkit-select"
        name={name}
        value={value}
        onChange={onChange}
        style={{
          borderRadius: "5px",
          padding: "10px",
          border: "1px solid #dbe1e5"
        }}
        {...rest}
      >
        {placeholder && <option value="">{placeholder}</option>}

        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
