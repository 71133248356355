import { useState, useEffect } from "react"
import useFetch from "../../utils/use-fetch"
import user from "../../utils/user"
import { loadStripe } from "@stripe/stripe-js"

function usePayment(context) {
  const [processing, setProcessing] = useState(false)
  const [stripe, setStripe] = useState(null)
  const [payment, setPayment] = useState(null)
  const [errors, setErrors] = useState(null)
  const [request] = useFetch(`https://${context.domain}/commerce/orders`)

  useEffect(() => {
    const load = async () => {
      const result = await loadStripe(context.stripe_key)
      setStripe(result)
    }

    load()
  }, [context.stripe_key])

  return [
    processing,
    payment,
    errors,
    stripe,
    onBuy(request, stripe, setErrors, setPayment, setProcessing)
  ]
}

function onBuy(request, stripe, setErrors, setPayment, setProcessing) {
  return async function ({
    card,
    name,
    email_address,
    total_amount,
    lines,
    address_country,
    skip_email_validation
  }) {
    setErrors(null)
    setProcessing(true)
    const result = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: { name }
    })

    if (result.error) {
      setErrors({
        messages: result.error.message
      })
      setProcessing(false)
    } else {
      try {
        const data = {
          lines: lines,
          total_amount: total_amount,
          payment_method: {
            id: result.paymentMethod.id
            // card: {
            //   last4:
            //     result.paymentMethod.card && result.paymentMethod.card.last4
            // }
          },
          trace_id: user.id(),
          email_address,
          name,
          address_country,
          skip_email_validation
        }

        const response = await request.post({ data: data })

        const orderData = response.order
        const confirmPath = `/${response.order.id}`

        // TODO: auth handling needs reworking
        const price = lines[0].price
        const coupon = lines[0].coupon
        const quantity = lines[0].quantity

        const authResponse = await handlePaymentAuth(
          request,
          confirmPath,
          stripe,
          price,
          coupon,
          quantity,
          orderData
        )

        if (authResponse.error) {
          setProcessing(false)

          setErrors({
            messages: [authResponse.error]
          })

          return
        }

        setProcessing(false)

        setPayment({
          cardBrand: result.paymentMethod.card.brand,
          last4: result.paymentMethod.card.last4,
          ...authResponse.purchaseData
        })
      } catch (error) {
        setProcessing(false)
        try {
          const json = await error.response.json()
          setErrors(json.errors)
        } catch {
          setErrors({
            messages: ["Couldn't complete your purchase."]
          })
        }
      }
    }
  }
}

function isAuthenticationRequired(authentication) {
  return authentication && authentication.required
}

// Handles payment authentication, if required.
// Returns an object containing purchaseData or an error.
async function handlePaymentAuth(
  request,
  complete3dsPath,
  stripe,
  price,
  coupon,
  quantity,
  purchaseData
) {
  const authentication = purchaseData.authentication

  if (!isAuthenticationRequired(authentication)) {
    return { purchaseData: purchaseData }
  }

  const response = await authenticatePayment(
    request,
    complete3dsPath,
    stripe,
    price,
    coupon,
    quantity,
    purchaseData
  )

  if (response.error) {
    return { error: response.error }
  }

  return { purchaseData: response.purchaseData }
}

// Shows the Stripe-supplied 3DS authentication dialog.
// Returns an object containing purchaseData or an error.
async function authenticatePayment(
  request,
  complete3dsPath,
  stripe,
  price,
  coupon,
  quantity,
  purchaseData
) {
  const authentication = purchaseData.authentication

  const response = await stripe.confirmCardPayment(authentication.client_secret)

  if (response.error) {
    return { error: response.error.message }
  } else if (response.paymentIntent.status != "succeeded") {
    return {
      error: `Could not complete payment: ${response.paymentIntent.status}`
    }
  } else if (complete3dsPath) {
    const data = {
      complete_3ds: true,
      customer_id: authentication.customer_id,
      stripe_invoice_id: authentication.stripe_invoice_id,
      pricing_id: price.id,
      amount: price.amount,
      quantity: quantity
    }

    if (coupon) {
      data.coupon_id = coupon.id
    }

    // If there's an error here, it'll bubble up and be handled by the caller
    const response = await request.post({
      path: complete3dsPath,
      data: data
    })

    return { purchaseData: response.purchase || response.subscription }
  }

  return { purchaseData: purchaseData }
}

export default usePayment
