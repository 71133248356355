import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useComponents } from "../../components"
import {
  enterAnimation,
  enterDelayedAnimation,
  exitAnimation
} from "./animation"
import Cards from "./cards"

function FulfillmentOrders({ order, isVisible, onAnimationDone }) {
  const Elements = useComponents()

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <motion.div
          className="formkit-card-inner--fulfillment"
          key="fulfillment-card-inner"
          animate={enterAnimation}
          exit={exitAnimation}
        >
          <Cards />
          <motion.div
            key="main-content-fulfillment"
            initial={{ opacity: 0 }}
            animate={enterDelayedAnimation}
            exit={exitAnimation}
          >
            <header className="formkit-confirmation-header">
              <Elements.Heading
                className="formkit-heading-primary font-display"
                name="confirmation_title"
                defaults={{
                  content: "Thank You!"
                }}
              />
            </header>
            <div className="formkit-main" role="main">
              <dl className="formkit-purchase-confirmation-details">
                <div>
                  <dt>Amount Paid</dt>
                  <dd>{order.total}</dd>
                </div>
                <div>
                  <dt>Order date</dt>
                  <dd>{order.created_at}</dd>
                </div>
                <div>
                  <dt>Payment method</dt>
                  <dd className="formkit-purchase-confirmation-card">
                    <svg className={`svg-card-${order.cardBrand}`}>
                      <use xlinkHref={`#${order.cardBrand}`} />
                    </svg>
                    {order.last4}
                  </dd>
                </div>
                {/* TODO: this will need changes to support installments */}
                {order.price.installments && (
                  <div>
                    <dt>Payment</dt>
                    <dd>1 of {order.price.installments}</dd>
                  </div>
                )}
                {nextInstallmentDateFormatted(order) && (
                  <div>
                    <dt>Next Payment</dt>
                    <dd>{nextInstallmentDateFormatted(order)}</dd>
                  </div>
                )}
              </dl>
              <Elements.Content
                className="formkit-content"
                group="content"
                name="content_fulfillment"
                defaults={{
                  content:
                    "<p>Your file has been emailed to you along with a receipt for your transaction. Click the link below to download it now.</p>"
                }}
              />
            </div>
            {order.fulfillment_details && order.fulfillment_details.length > 0 && (
              <div className="formkit-purchase-confirmation-ticket-stub formkit-purchase-confirmation-ticket-stub__orders">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 492 24"
                >
                  <path
                    fill="currentColor"
                    stroke="currentColor"
                    d="M11.5 12c0 6.1837-4.88069 11.2275-11 11.4893V.510674C6.61931.772452 11.5 5.81625 11.5 12zM491.5 23.4893c-6.119-.2618-11-5.3056-11-11.4893 0-6.18375 4.881-11.227548 11-11.489326V23.4893z"
                  />
                  <path
                    stroke="currentColor"
                    strokeDasharray="8 8"
                    strokeMiterlimit="14.3356"
                    strokeWidth="2"
                    d="M12 12h468"
                  />
                </svg>
                <div style={{ padding: "0 3rem" }}>
                  <h2>File details</h2>
                </div>
                {order.fulfillment_details.map((fulfillment) => (
                  <div key={fulfillment.url}>
                    <div
                      className="formkit-button-container"
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "space-between"
                      }}
                    >
                      <div
                        style={{
                          flex: "0 1 auto"
                        }}
                      >
                        {fulfillment.fulfillment_type == "download"
                          ? fulfillment.product_name
                          : "Paid Newsletter"}
                      </div>
                      <div
                        style={{
                          flex: "0 1 auto"
                        }}
                      >
                        <Elements.Button
                          as="a"
                          className="formkit-purchase-button"
                          group="purchase_button"
                          name="purchase-download-button"
                          defaults={{
                            content:
                              fulfillment.fulfillment_type == "download"
                                ? "Download"
                                : "Read"
                          }}
                          href={fulfillment.url}
                          target="_blank"
                          rel="noreferrer"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const nextInstallmentDateFormatted = (order) => {
  // Depending on if we're previewing a price or have a successful purchase, the
  // next_installment_date_formatted will be available on different objects. This
  // function will return either, if available.
  if (
    order.next_installment_date_formatted ||
    order.price.next_installment_date_formatted
  ) {
    return (
      order.next_installment_date_formatted ||
      order.price.next_installment_date_formatted
    )
  } else {
    return false
  }
}

export default FulfillmentOrders
