import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useComponents } from "../../components"
import { enterAnimation, exitAnimation } from "./animation"
import { discountExplanationMessage } from "../../utils/price-functions"

const CouponErrors = ({ error }) => {
  if (!error) {
    return null
  }

  return <div className="error">{error}</div>
}

function SalePage({
  styles,
  prices,
  priceType,
  coupon,
  couponErrors,
  maxQuantity,
  onClick,
  isVisible,
  onAnimationDone,
  selectPrice,
  activePrice,
  selectQuantity
}) {
  const Elements = useComponents()

  const discountExplanationMessageStyles = {
    marginTop: "10px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: styles.forElement("price").color
  }

  const showTopPrice = prices.length == 1 && priceType != "pay_what_you_want"
  const showMultiplePrices = prices.length > 1
  const showPayWhatYouWantInput =
    prices.length == 1 && priceType == "pay_what_you_want"
  const showQuantity = typeof maxQuantity !== "undefined" && maxQuantity != 1

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <div className="formkit-card-inner">
          <motion.div
            key="img-container"
            className="formkit-image-container"
            initial={{ opacity: 0 }}
            animate={enterAnimation}
            exit={exitAnimation}
          >
            <Elements.Image
              className="formkit-image"
              name="image"
              size={{ h: 400, w: 200 }}
              defaults={{
                src:
                  "https://cdn.convertkit.com/assets/images/commerce/product-placeholder.png"
              }}
            />
          </motion.div>
          <motion.div
            key="main-content"
            className="formkit-main"
            initial={{ opacity: 0 }}
            animate={enterAnimation}
            exit={exitAnimation}
          >
            <header>
              {/* If product only has one price, render it at the top */}
              {showTopPrice && (
                <Elements.Price
                  className="formkit-product-price"
                  style={styles.forElement("price")}
                  price={prices[0]}
                  coupon={coupon}
                />
              )}

              <Elements.Heading
                className="formkit-heading-primary font-display"
                name="product_title"
                defaults={{
                  content: "Learn to cook ramen by Russo's famous dishes!"
                }}
              />
            </header>
            <div role="main">
              <CouponErrors error={couponErrors} />
              <Elements.Content
                className="formkit-content"
                name="content"
                defaults={{
                  content:
                    "<p>Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis.</p>"
                }}
              />

              <div className="formkit-divider" />

              {showMultiplePrices && (
                <Elements.MultiplePrices
                  className="formkit-product-price"
                  coupon={coupon}
                  selectPrice={selectPrice}
                  prices={prices}
                  activePrice={activePrice}
                  activePriceBorderColor={
                    styles.forElement("purchase_button").backgroundColor
                  }
                  textColor={styles.forElement("content").color}
                />
              )}

              {showPayWhatYouWantInput && (
                <Elements.PayWhatYouWantInput
                  className="formkit-product-pay-what-you-want-input"
                  style={styles.forElement("pay-what-you-want-input")}
                  price={prices[0]}
                  selectPrice={selectPrice}
                  styles={styles}
                  textColor={styles.forElement("content").color}
                />
              )}

              {showQuantity && (
                <Elements.QuantityInput
                  className="formkit-product-quantity-input"
                  style={styles.forElement("quantity-input")}
                  maxQuantity={maxQuantity}
                  selectQuantity={selectQuantity}
                  styles={styles}
                  textColor={styles.forElement("content").color}
                />
              )}

              <motion.div
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.95 }}
              >
                <Elements.Button
                  className="formkit-purchase-button"
                  name="purchase_button"
                  onClick={onClick}
                  defaults={{
                    content: "Get it now!"
                  }}
                />
              </motion.div>
              <div style={discountExplanationMessageStyles}>
                {discountExplanationMessage(activePrice, coupon)}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default SalePage
