import { useState, useEffect } from "react"
import useFetch from "../../utils/use-fetch"

function useTaxConfig(context) {
  const [data, setData] = useState(null)
  const [request] = useFetch(
    `https://${context.domain}/commerce/taxes/configuration?product_id=${context.product_id}`
  )

  useEffect(() => {
    let mounted = false

    const fetchData = async () => {
      try {
        const response = await request.get(false)

        if (response.configuration && !mounted) {
          setData(response.configuration)
          mounted = true
        }
      } catch {
        // NOOP
      }
    }

    if (context.tax_collectible && !mounted) {
      fetchData()
    }
  }, [context.tax_collectible])

  return data
}

export default useTaxConfig
