const USER_ID_KEY = "ckid"

// From https://gist.github.com/jed/982883
const uuid = (a) =>
  a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid)

const generateUserId = () => {
  const userId = uuid()
  window.localStorage.setItem(USER_ID_KEY, userId)
  return userId
}

const getUserId = () => window.localStorage.getItem(USER_ID_KEY)

const id = () => getUserId() || generateUserId()

export default { id }
