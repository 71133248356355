import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import {
  enterAnimation,
  enterDelayedAnimation,
  exitAnimation
} from "./animation"
import { useComponents } from "../../components"
import {
  CardElement,
  Elements as StripeElements,
  useElements // alias as useStripeElements ??
} from "@stripe/react-stripe-js"
import usePayment from "./use-payment-orders"

import {
  formatCheckout,
  formatInstallmentsDuration,
  formatInstallmentsPrice,
  formatPrice,
  totalLineAmount,
  discountedPrice
} from "../../utils/price-functions"

import SelectInput from "../../render/elements/select-input"
import useTaxConfig from "./use-tax-config"
import useCountries from "./use-countries"
import EmailValidation from "./email-validation"

export function hexToRgb(hex) {
  const [r, g, b] = hex.match(/\w{2}/g).map((c) => parseInt(c, 16))
  return `${r},${g},${b}`
}

function PlusIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.34315 11.25C5.92893 11.25 5.59315 11.5858 5.59315 12C5.59315 12.4142 5.92893 12.75 6.34315 12.75H11.25V17.6569C11.25 18.0711 11.5858 18.4069 12 18.4069C12.4142 18.4069 12.75 18.0711 12.75 17.6569V12.75H17.6569C18.0711 12.75 18.4069 12.4142 18.4069 12C18.4069 11.5858 18.0711 11.25 17.6569 11.25H12.75V6.34315C12.75 5.92893 12.4142 5.59315 12 5.59315C11.5858 5.59315 11.25 5.92893 11.25 6.34315V11.25L6.34315 11.25Z" />
    </svg>
  )
}

function TimesIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.53033 6.46967C7.23744 6.17678 6.76256 6.17678 6.46967 6.46967C6.17678 6.76256 6.17678 7.23744 6.46967 7.53033L10.9393 12L6.46967 16.4697C6.17678 16.7626 6.17678 17.2374 6.46967 17.5303C6.76256 17.8232 7.23744 17.8232 7.53033 17.5303L12 13.0607L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L13.0607 12L17.5303 7.53033C17.8232 7.23744 17.8232 6.76256 17.5303 6.46967C17.2374 6.17678 16.7626 6.17678 16.4697 6.46967L12 10.9393L7.53033 6.46967Z" />
    </svg>
  )
}

function CheckoutOrders({
  styles,
  price,
  coupon,
  context,
  quantity,
  onPurchase,
  onBack,
  onCouponFailure,
  isVisible,
  onAnimationDone,
  editing
}) {
  const [processing, payment, errors, stripe, buy] = usePayment(context)
  const Elements = useComponents()
  const [upsellsAdded, setUpsellsAdded] = useState([])
  let [availableUpsells, setAvailableUpsells] = useState(context.upsells || [])
  const [upsellLines, setUpsellLines] = useState([])

  const [emailValidation, setEmailValidation] = useState({})

  const quantityLabel = quantity > 1 ? `${quantity}x` : ""
  const totalLabel = quantity > 1 ? "Total:" : ""
  const stripeElementOptions = context.stripe_acct
    ? { onBehalfOf: context.stripe_acct }
    : {}

  function getContentColor() {
    if (editing) {
      return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--content-color")
    }
    return styles.forElement("content").color
  }

  const contentColor = getContentColor()

  const initialLines = [
    {
      product_id: context.product_id,
      price_id: price.id,
      unit_amount: price.amount,
      quantity: quantity,
      coupon: coupon,
      amount:
        coupon === null
          ? price.amount * quantity
          : discountedPrice(price, coupon, quantity),
      currency: price.currency
    }
  ]

  const showAddUpsellButton =
    context.add_upsell && context.upsells && context.upsells.length < 2
  const upsellsAvailable =
    (context.upsells && context.upsells.length > 0) ||
    availableUpsells.length >= 1
  const showAddUpsellsToOrder = upsellsAvailable && upsellsAdded.length < 2

  useEffect(() => {
    if (!processing && payment) {
      onPurchase(payment)
    }
  }, [processing, payment])

  useEffect(() => {
    if (errors && errors.keys && errors.keys.includes("coupon")) {
      onCouponFailure(errors)
    }

    if (errors && errors.email_validation) {
      setEmailValidation(errors.email_validation)
    } else {
      setEmailValidation({})
    }
  }, [errors])

  useEffect(() => {
    if (contentColor) {
      document.documentElement.style.setProperty(
        "--content-color--rbg",
        hexToRgb(getContentColor())
      )
    }
  }, [contentColor])

  const handleOpenSettings = () => {
    if (!editing) {
      return
    }
    postOpenSettingsMessage()
  }

  const postOpenSettingsMessage = () => {
    window.parent.postMessage("open-settings", "*")
    const url = new URL(window.location)
    url.searchParams.set("activeTab", "upsells")
    window.history.pushState({}, "", url)
  }

  let addOrRemoveUpsellToOrder = (upsell) => {
    const index = upsellsAdded.indexOf(upsell)
    if (index === -1) {
      addToUpsellLines(upsell)
      removeFromAvailable(upsell)
    } else {
      removeFromUpsellLines(upsell, index)
      addToAvailable(upsell)
    }
  }

  const addToUpsellLines = (upsell) => {
    setUpsellLines(upsellLines.concat(linesForUpsell(upsell)))
    setUpsellsAdded(upsellsAdded.concat(upsell))
  }

  const removeFromAvailable = (upsell) => {
    availableUpsells.splice(availableUpsells.indexOf(upsell), 1)
    setAvailableUpsells(availableUpsells.concat([]))
  }

  const removeFromUpsellLines = (upsell, index) => {
    upsellLines.splice(index, 1)
    setUpsellLines(upsellLines.concat([]))
    upsellsAdded.splice(index, 1)
    setUpsellsAdded(upsellsAdded.concat([]))
  }

  const addToAvailable = (upsell) => {
    setAvailableUpsells(availableUpsells.concat(upsell))
  }

  const linesForUpsell = (upsell) => ({
    upsell_id: upsell.id,
    product_id: upsell.upsold_product_id,
    unit_amount: upsell.upsold_product_prices.plans[0].amount,
    quantity: 1,
    coupon: upsell.coupon,
    amount:
      upsell.coupon === null
        ? upsell.upsold_product_prices.plans[0].amount
        : discountedPrice(
            upsell.upsold_product_prices.plans[0],
            upsell.coupon,
            1
          )
  })

  if (editing) {
    availableUpsells = context.upsells
    addOrRemoveUpsellToOrder = handleOpenSettings
  }

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <motion.div
          key="checkout-card-inner"
          className={`formkit-card-inner--checkout`}
          animate={enterAnimation}
          exit={exitAnimation}
        >
          <motion.div
            key="main-content-checkout"
            className="formkit-main formkit-main__orders"
            initial={{ opacity: 0 }}
            animate={enterDelayedAnimation}
          >
            <header>
              <h1 className="formkit-heading-primary formkit-heading-primary__orders font-display">
                <button
                  className="formkit-back-button__orders"
                  style={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                  title="Back"
                  onClick={onBack}
                >
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                  >
                    <path d="M3.97 11.467a.75.75 0 0 0 0 1.064l6.016 5.977a.75.75 0 0 0 1.057-1.064l-4.735-4.705H19.5a.75.75 0 0 0 0-1.5H6.328l4.715-4.685A.75.75 0 0 0 9.986 5.49L3.97 11.467z"></path>
                  </svg>
                </button>
                Order summary
              </h1>
              <div className="formkit-product-summary">
                <div className="formkit-image__orders">
                  <Elements.Image
                    className="formkit-images formkit-image__orders"
                    name="image"
                    size={{ h: 400, w: 200 }}
                    defaults={{
                      src:
                        "https://cdn.convertkit.com/assets/images/commerce/product-placeholder.png"
                    }}
                  />
                </div>
                <div className="formkit-product-summary__details">
                  <h2 className="formkit-product-summary__title__orders">
                    {quantityLabel} {context.name}
                  </h2>
                  <p className="formkit-product-summary__price">
                    {price.installments && (
                      <>
                        {formatInstallmentsDuration(price, coupon)} <br />
                        <span style={{ fontSize: "0.9rem", opacity: "0.6" }}>
                          {formatInstallmentsPrice(price, coupon)}
                        </span>
                      </>
                    )}

                    {!price.installments && (
                      <>
                        {totalLabel} {formatCheckout(price, coupon, quantity)}
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div>
                {upsellsAdded && (
                  <>
                    {upsellsAdded.map((upsell) => (
                      <Upsell
                        key={upsell.id}
                        upsell={upsell}
                        handleClickUpsell={addOrRemoveUpsellToOrder}
                        iconName="times"
                        editing={editing}
                      />
                    ))}
                  </>
                )}
              </div>
            </header>
            {showAddUpsellsToOrder && (
              <>
                {context.upsells !== 0 && (
                  <h3 className="formkit-upsell-product__title__orders">
                    Add to your order
                  </h3>
                )}
                {availableUpsells.map((upsell) => (
                  <Upsell
                    key={upsell.id}
                    upsell={upsell}
                    handleClickUpsell={addOrRemoveUpsellToOrder}
                    iconName="plus"
                    editing={editing}
                  />
                ))}
              </>
            )}
            {showAddUpsellButton && (
              <>
                <button
                  className="p-4 w-full items-center add_upsell_button__orders"
                  onClick={postOpenSettingsMessage}
                >
                  <PlusIcon />{" "}
                  <p className="add_upsell_button_text__orders">
                    Add an upsell
                  </p>
                </button>
              </>
            )}
            <div className="formkit-divider" />
            <div role="main">
              {errors && <PaymentError message={errors.messages} />}
              <StripeElements stripe={stripe} options={stripeElementOptions}>
                <CheckoutForm
                  stripe={stripe}
                  onSubmit={buy}
                  lines={initialLines.concat(upsellLines)}
                  processing={processing}
                  context={context}
                  labelStyles={styles.forElement("purchase_label")}
                  inputStyles={styles.forElement("purchase_input")}
                  currency={price.currency}
                  emailValidation={emailValidation}
                />
              </StripeElements>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Upsell = ({ upsell, handleClickUpsell, iconName, editing }) => {
  const upsoldImageSrc = (upsell) =>
    upsell.upsold_product_src ||
    "https://cdn.convertkit.com/assets/images/commerce/product-placeholder-square-small.png"

  const handleImageClick = () => {
    if (editing) {
      return
    }
    window.open(upsell.upsold_product_url, "_blank")
  }

  return (
    <div
      className="formkit-product-summary formkit-product-upsell"
      key={upsell.id}
    >
      <button
        className="formkit-product-summary__upsell_button w-full"
        onClick={editing ? handleClickUpsell : null}
      >
        <img
          className="formkit-image__orders"
          src={upsoldImageSrc(upsell)}
          onClick={handleImageClick}
          role="button"
        />
        <div className="formkit-product-summary__details formkit-product-upsell">
          <h2 className="formkit-product-summary__title__orders">
            {upsell.upsold_product_name}
          </h2>
          <p className="formkit-product-summary__price">
            <span
              className={`${
                upsell.coupon.amount ? "upsell-full-price-discounted" : ""
              }`}
            >
              {upsell.upsold_product_prices.plans[0].formatted}
            </span>
            {upsell.coupon.amount && (
              <>
                {upsell.upsold_product_prices.type === "recurring" && (
                  <>
                    {formatInstallmentsDuration(
                      upsell.upsold_product_prices.plans[0],
                      upsell.coupon
                    )}{" "}
                    <br />
                    <span style={{ fontSize: "0.9rem", opacity: "0.6" }}>
                      {formatInstallmentsPrice(
                        upsell.upsold_product_prices.plans[0],
                        upsell.coupon
                      )}
                    </span>
                  </>
                )}
                {upsell.upsold_product_prices.type != "recurring" && (
                  <>
                    {formatCheckout(
                      upsell.upsold_product_prices.plans[0],
                      upsell.coupon,
                      1
                    )}
                  </>
                )}
              </>
            )}
          </p>
        </div>
        <div
          className="formkit-product-summary__upsell_button_icon_container"
          onClick={() => handleClickUpsell(upsell)}
        >
          {iconName === "times" ? <TimesIcon /> : <PlusIcon />}
        </div>
      </button>
    </div>
  )
}

const CheckoutForm = ({
  stripe,
  onSubmit,
  lines,
  processing,
  labelStyles,
  inputStyles,
  context,
  currency,
  emailValidation
}) => {
  const [state, setState] = useState({
    email_address: "",
    name: "",
    address_country: ""
  })

  const [emailInvalid, setEmailInvalid] = useState(
    emailValidation.email_invalid
  )
  const [emailDidYouMean, setEmailDidYouMean] = useState(
    emailValidation.did_you_mean
  )
  const [skipEmailValidation, setSkipEmailValidation] = useState(false)

  const taxConfig = useTaxConfig(context)
  const taxCollectionEnabled = taxConfig && taxConfig.collection_enabled
  const { countries, request_country } = useCountries(
    context,
    taxCollectionEnabled
  )

  const textOpacity = processing ? 0 : 1
  const svgOpacity = processing ? 1 : 0

  const elements = useElements()

  useEffect(() => {
    setEmailInvalid(emailValidation.email_invalid)
    setEmailDidYouMean(emailValidation.did_you_mean)
  }, [emailValidation])

  useEffect(() => {
    if (!state.address_country && request_country) {
      setState({ ...state, address_country: request_country })
    }
  }, [request_country])

  const handleChange = (event) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value })

    if (name == "email_address") {
      setEmailInvalid(false)
      setEmailDidYouMean(null)
      setSkipEmailValidation(false)
    }
  }

  const handleSuggestionAccepted = () => {
    setState({ ...state, email_address: emailDidYouMean })
    setEmailDidYouMean(null)
    setEmailInvalid(false)
    setSkipEmailValidation(true)
  }

  const handleSuggestionIgnored = () => {
    setEmailDidYouMean(null)
    setEmailInvalid(false)
    setSkipEmailValidation(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    onSubmit({
      card: elements.getElement(CardElement),
      total_amount: totalAmount,
      skip_email_validation: skipEmailValidation,
      lines,
      ...state
    })
  }

  const totalAmount = totalLineAmount(lines)

  return (
    <form onSubmit={handleSubmit}>
      <div className="formkit-heading-primary formkit-heading-primary__orders">
        Pay with card
      </div>
      <div className="formkit-field">
        <label htmlFor="email_address" style={labelStyles}>
          Email
        </label>
        <input
          className="input"
          id="email_address"
          type="email"
          name="email_address"
          pattern="^.+@.+\..+$"
          required
          value={state.email_address}
          onChange={handleChange}
        />
        {!emailInvalid && (
          <span className="formkit-input-help">
            You’ll receive receipts and notifications at this email address.
          </span>
        )}
        {emailInvalid && (
          <EmailValidation
            emailInvalid={emailInvalid}
            emailSuggestion={emailDidYouMean}
            onSuggestionAccepted={handleSuggestionAccepted}
            onSuggestionIgnored={handleSuggestionIgnored}
          />
        )}
      </div>

      {taxCollectionEnabled && countries && (
        <SelectInput
          autoComplete="address-level1"
          required={true}
          data-element="country-select"
          label="Location"
          placeholder="Select your country"
          labelStyles={labelStyles}
          name="address_country"
          value={state.address_country}
          onChange={handleChange}
          options={countries}
        />
      )}

      <div className="formkit-field">
        <label htmlFor="name" style={labelStyles}>
          Name on card
        </label>
        <input
          id="name"
          name="name"
          className="input"
          type="text"
          placeholder="Jane Smith"
          autoComplete="name"
          required
          value={state.name}
          onChange={handleChange}
        />
      </div>
      <div className="formkit-field">
        <label htmlFor="card" style={labelStyles}>
          Card
        </label>
        <CardElement
          id="card"
          options={{
            style: {
              base: {
                iconColor: "#424770",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                color: inputStyles.color,
                ":-webkit-autofill": {
                  color: inputStyles.color
                },
                "::placeholder": {
                  color: `${inputStyles.color}90`
                }
              },
              invalid: {
                color: "#9e2146",
                iconColor: "#9e2146"
              }
            }
          }}
        />
      </div>
      <div className="formkit-divider" />
      <motion.button
        className="formkit-purchase-button"
        type="submit"
        disabled={!stripe || processing}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.95 }}
      >
        <motion.span
          key="purchase-button-text"
          animate={{ opacity: textOpacity }}
        >
          {/* TODO: Get this total amount from the server */}
          Pay {formatPrice(totalAmount, currency)}
        </motion.span>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          initial={{ opacity: 0, transform: "scale(0)" }}
          animate={{
            opacity: svgOpacity,
            transform: `scale(${svgOpacity})`
          }}
        >
          <path d="M25.251 6.461c-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615V6.461z">
            <animateTransform
              attributeName="transform"
              attributeType="xml"
              dur="0.6s"
              from="0 25 25"
              repeatCount="indefinite"
              to="360 25 25"
              type="rotate"
            />
          </path>
        </motion.svg>
      </motion.button>

      {taxCollectionEnabled && taxConfig.vat.enabled && (
        <span
          className="formkit-input-help"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          All prices are inclusive of VAT
        </span>
      )}
    </form>
  )
}

const PaymentError = ({ message }) => {
  return (
    <motion.div
      key="payment-error-message"
      className="error"
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: 1,
        height: "auto",
        transition: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          opacity: { delay: 0.1 }
        }
      }}
      exit={{
        opacity: 0,
        height: 0,
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 35,
          height: { delay: 0.3 }
        }
      }}
    >
      {message}
    </motion.div>
  )
}

export default CheckoutOrders
