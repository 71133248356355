import { useState } from "react"

function createURL(url, path, queryParams = {}) {
  let location = [url, path].filter(Boolean).join("")

  const queryString = new URLSearchParams()
  Object.entries(queryParams).forEach((pair) => {
    queryString.set(...pair)
  })

  if (queryString.toString().length) {
    location = location + `?${queryString.toString()}`
  }

  return location
}

function createRequest({ url, method = "GET", setLoading }) {
  return async (options = {}) => {
    let fetchOptions = {
      headers: options.headers || {}
    }

    if (method !== "GET") {
      fetchOptions.method = method
      fetchOptions.headers = {
        ...fetchOptions.headers,
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }

    if (options.data) {
      fetchOptions.body = JSON.stringify(options.data)
    }

    try {
      setLoading(true)
      const response = await fetch(
        createURL(url, options.path, options.queryParams),
        fetchOptions
      )

      if (!response.ok) {
        const err = new Error(response.statusText)
        err.response = response
        throw err
      }

      const json = await response.json()
      setLoading(false)
      return json
    } finally {
      setLoading(false)
    }
  }
}

/**
 * == Example
 * const [data, setData] = useState()
 * const [request, loading] = useFetch("https://convertkit.com")
 *
 * useEffect(() => {
 *   const fetchData = async () => {
 *     const response = await request.get({ path: "/data" })
 *     setData(response)
 *   }
 *   fetchData()
 * }, [])
 *
 * @param {string} url
 */
function useFetch(url) {
  const [loading, setLoading] = useState(false)

  const request = {
    get: createRequest({ url, setLoading }),
    post: createRequest({ url, setLoading, method: "POST" }),
    put: createRequest({ url, setLoading, method: "PUT" }),
    delete: createRequest({ url, setLoading, method: "DELETE" })
  }

  return [request, loading]
}

export default useFetch
