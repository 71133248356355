import { useState, useEffect } from "react"
import useFetch from "../../utils/use-fetch"

function useCountries(context, mount = false) {
  const [data, setData] = useState({})
  const [request] = useFetch(
    `https://${context.domain}/commerce/taxes/countries`
  )

  useEffect(() => {
    let mounted = false

    const fetchData = async () => {
      try {
        const response = await request.get()

        if (response.countries && !mounted) {
          setData(response)
          mounted = true
        }
      } catch {
        // NOOP
      }
    }

    if (mount && !mounted) {
      fetchData()
    }
  }, [mount])

  return data
}

export default useCountries
